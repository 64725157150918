<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("client.billing") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: routeToTable })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="saveData">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">{{ $t("client.billing") }}:</h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.billingAddress1") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="client.billing_information.addressLine1"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.billingAddress2") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="client.billing_information.addressLine2"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.billingAddress3") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="client.billing_information.addressLine3"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.billingStreet") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="client.billing_information.street"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.billingZipcode") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="client.billing_information.zipcode"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.billingCity") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="client.billing_information.city"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.billingCountry") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <v-select
                v-model="client.billing_information.country"
                class="form-control"
                :items="countries"
                item-text="label_german"
                item-value="id"
                :menu-props="{ offsetY: true }"
              />
              <div class="input-group-append">
                <span class="input-group-text border-0">
                  <i class="fal fa-flag icon-lg" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Client from "@/components/Admins/Clients/clients";
import Countries from "@/components/Admins/Settings/System/Countries/countries";
import ApiService from "@/core/services/api.service";
export default {
  props: {
    routeToTable: {
      type: String,
      default: "adminTenants"
    }
  },
  data() {
    return {
      client: {
        billing_information: {}
      },
      isBusy: false,
      countries: []
    };
  },
  mounted() {
    this.getClient();
    this.getCountries();
  },
  methods: {
    getClient() {
      this.isBusy = true;
      let clientId = this.$route.params.id;
      Client.get(clientId)
        .then(response => {
          let client = response.data.data;
          this.validateProps(client);
          this.isBusy = false;
        })
        .catch(error => {
          if (error.response && error.response.data) {
            this.$swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
          }
          this.isBusy = false;
        });
    },
    getCountries() {
      if (ApiService.isCached("countries")) {
        this.countries = ApiService.isCached("countries");
        return;
      }

      const params = {
        size: 999
      };
      Countries.getAll(params)
        .then(response => {
          this.countries = response.data.data;
          ApiService.setCache("countries", this.countries, 1000 * 60 * 60);
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    saveData() {
      this.isBusy = true;
      const data = this.client;
      let clientId = this.$route.params.id;
      Client.update(clientId, data)
        .then(response => {
          let client = response.data.data;
          this.validateProps(client);
          this.$toast.fire({
            icon: "success",
            title: this.$t("client.clientUpdated")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    validateProps(client) {
      if (!client.billing_information) client.billing_information = {};
      this.client = client;
      if (client.language !== null) {
        this.client.language_id = client.language.id;
      }
    }
  }
};
</script>
