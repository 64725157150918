<template>
  <div id="userGrantedIpsCard" class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("usersGrantIps.usersGrantedIps") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: routeToLoginSecurity })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary btn-hover" @click.prevent="showCreate">
          <i class="fal fa-plus"></i>
          {{ $t("usersGrantIps.addNewEntry") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <div class="row justify-content-between align-items-center mb-3">
        <!--begin::Pagination-->
        <div class="col-auto d-flex align-items-center">
          <b-form-select
            v-model="pageSize"
            :options="pageSizes"
            class="mr-3 shadow-none"
            value-field="item"
            text-field="name"
            size="sm"
            disabled-field="notEnabled"
            @change="handlePageSizeChange"
          ></b-form-select>
          <span class="text-muted text-no-wrap">
            {{ $t("general.recordsPerPage") }}
          </span>
        </div>
        <div class="col-auto">
          <b-input
            v-model="searchTitle"
            :placeholder="$t('search.searchTerm')"
          />
        </div>
        <!--end:: Pagination-->
      </div>
      <div class="dataTables_wrapper">
        <b-table
          responsive
          :items="userGrantedIps"
          :fields="fields"
          class="dataTable table table-head-custom"
          :filter="searchTitle"
          show-empty
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(user_id)="data">
            <div>
              <span>#{{ data.item.user_id }}</span>
              <span v-if="data.item.user"> - {{ data.item.user.name }}</span>
              <span v-if="!data.item.user_id">
                - {{ $t("users.allUsers") }}</span
              >
            </div>
          </template>
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
            <b
              v-if="data.item.user_id"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="
                deleteUserIp(data.item.id, data.item.ip, data.item.user.name)
              "
            >
              <i class="fal fa-trash"></i>
            </b>
            <b
              v-else
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteUserIp(data.item.id, data.item.ip)"
            >
              <i class="fal fa-trash"></i>
            </b>
          </template>
          <template #cell(active)="data">
            <span
              v-if="data.item.active"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("general.active") }}
            </span>
            <span v-else class="label label-lg label-light-danger label-inline">
              {{ $t("general.inactive") }}
            </span>
          </template>
        </b-table>
      </div>
      <div class="row justify-content-between align-items-center mt-3">
        <div class="col-auto">
          <span v-if="userGrantedIps.length" class="text-muted text-no-wrap">
            {{
              $t("general.records", {
                from: meta.from,
                to: meta.to,
                total: meta.total
              })
            }}
          </span>
        </div>
        <div class="col-auto d-flex flex-wrap">
          <b-pagination
            v-model="page"
            :total-rows="totalRecords"
            :per-page="pageSize"
            size="md"
            class="pl-0 mb-0"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { formatDate } from "@/components/Tools/modifiers";
import UserGrantIps from "@/components/Tenants/UserGrantIps/userGrantIps";

export default {
  name: "TenantsUserGrantIpsTable",
  props: {
    routeToLoginSecurity: String,
    routeToWhitelistCreate: String,
    routeToWhitelistEdit: String
  },
  data() {
    return {
      userGrantedIps: [],
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "user_id",
          label: this.$t("usersGrantIps.user"),
          sortable: true
        },
        {
          key: "ip",
          label: this.$t("usersGrantIps.ipAddress"),
          sortable: true
        },
        {
          key: "description",
          label: this.$t("usersGrantIps.description"),
          sortable: true
        },
        {
          key: "active",
          label: this.$t("table.status"),
          sortable: true
        },
        {
          key: "updated_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.updatedAt"),
          sortable: true
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false
        }
      ],
      // Meta info
      meta: [],
      page: 1,
      totalPages: 10,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalRecords: 0,
      isBusy: false
    };
  },
  mounted() {
    this.loadUserGrantedIps();
  },
  methods: {
    getRequestParams(page, pageSize) {
      let params = {};
      if (page) params["page"] = page;
      if (pageSize) params["size"] = pageSize;
      return params;
    },
    setMeta(meta) {
      this.meta = meta;
      this.totalPages = meta.last_page;
      this.totalRecords = meta.total;
    },
    loadUserGrantedIps() {
      this.isBusy = true;

      const params = this.getRequestParams(this.page, this.pageSize);

      UserGrantIps.getAll(params)
        .then(response => {
          this.userGrantedIps = response.data.data;
          this.setMeta(response.data.meta);
          this.isBusy = false;
        })
        .catch(e => {
          console.log(e);
          this.isBusy = false;
        });
    },
    handlePageChange(value) {
      this.page = value;
      this.loadUserGrantedIps();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.loadUserGrantedIps();
    },
    formatDateAssigned(value) {
      return formatDate(value);
    },
    showDetails(id) {
      this.$router.push({
        name: this.routeToWhitelistEdit,
        params: { whitelistId: id }
      });
    },
    showCreate() {
      this.$router.push({ name: this.routeToWhitelistCreate });
    },
    deleteUserIp(id, ip, userName = false) {
      let messageText = this.$t("usersGrantIps.confirmDeleteText", { ip: ip });
      if (userName) {
        messageText = this.$t("usersGrantIps.confirmDeleteTextUser", {
          ip: ip,
          user: userName
        });
      }

      Swal.fire({
        title: this.$t("usersGrantIps.confirmDeleteTitle"),
        text: messageText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          UserGrantIps.delete(id)
            .then(() => {
              this.$toast.fire({
                icon: "success",
                title: this.$t("usersGrantIps.deleteSuccessText")
              });
              if (this.userGrantedIps.length === 1 && this.page !== 1) {
                this.handlePageChange(this.page - 1);
              } else {
                this.loadUserGrantedIps();
              }
              this.isBusy = false;
            })
            .catch(error => {
              Swal.fire("Error", error.response.data.message, "error");
            });
        }
      });
    }
  }
};
</script>
