var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"flex-row-auto offcanvas-mobile w-300px w-xl-350px",attrs:{"id":"kt_profile_aside"}},[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body pt-4"},[_c('div',{staticClass:"d-flex justify-content-end"}),_c('div',{staticClass:"row py-3"},[_c('div',{staticClass:"col-md-4 col-12 d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-60 symbol-lg-70 details-symbol"},[_c('div',{staticClass:"symbol-label symbol-label-profile",style:(_vm.companyLogo
                      ? 'background-image: url(' +
                        _vm.companyLogo +
                        '); background-color: #ffffff;'
                      : '')})])]),_c('div',{staticClass:"col-md-8 col-12 row align-items-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"font-weight-bolder font-size-h5 text-dark-75"},[(!_vm.client.id)?_c('b-skeleton',{attrs:{"type":"text","height":"1.5rem"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.client.name))])],1),_c('div',{staticClass:"text-muted"},[(!_vm.client.id)?_c('b-skeleton',{attrs:{"type":"text","width":"60%"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.client.number))])],1)])])]),_c('div',{staticClass:"navi navi-bold navi-hover navi-active navi-link-rounded"},_vm._l((_vm.tabNavigation),function(item,i){return _c('div',{key:i,staticClass:"navi-item mb-2"},[_c('router-link',{attrs:{"to":{ name: item.route },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"navi-link py-4",class:{
                    active:
                      isExactActive ||
                      (_vm.$route.name.includes(item.route) &&
                        item.route !== _vm.detailRoutes.details)
                  },attrs:{"href":href}},[(item.icon)?_c('span',{staticClass:"navi-icon"},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('span',{staticClass:"navi-text"},[_vm._v(_vm._s(_vm.$t(item.label)))])])]}}],null,true)})],1)}),0)])])]),_c('div',{staticClass:"flex-row-fluid ml-lg-8 client-details"},[(_vm.$route.name === _vm.detailRoutes.details)?_c('clientInformation',{attrs:{"route-to-table":_vm.detailRoutes.table},on:{"client-updated":_vm.loadClient}}):(_vm.$route.name === _vm.detailRoutes.contact)?_c('clientContact',{attrs:{"route-to-table":_vm.detailRoutes.table}}):(_vm.$route.name === _vm.detailRoutes.billing)?_c('clientBilling',{attrs:{"route-to-table":_vm.detailRoutes.table}}):(_vm.$route.name === _vm.detailRoutes.accounting)?_c('clientAccounting',{attrs:{"route-to-table":_vm.detailRoutes.table}}):(_vm.$route.name === _vm.detailRoutes.storage)?_c('clientStorage',{attrs:{"route-to-table":_vm.detailRoutes.table}}):(_vm.$route.name === _vm.detailRoutes.database)?_c('clientDatabases',{attrs:{"route-to-table":_vm.detailRoutes.table}}):(_vm.$route.name === _vm.detailRoutes.loginSecurity)?_c('clientLoginSecurity',{attrs:{"route-to-table":_vm.detailRoutes.table,"route-to-whitelist":_vm.detailRoutes.loginSecurityUserGrantIpTable}}):(_vm.$route.name === _vm.detailRoutes.loginSecurityUserGrantIpTable)?_c('UserGrantedIpsTable',{attrs:{"route-to-login-security":_vm.detailRoutes.loginSecurity,"route-to-whitelist-create":_vm.detailRoutes.loginSecurityUserGrantIpCreate,"route-to-whitelist-edit":_vm.detailRoutes.loginSecurityUserGrantIpEdit}}):(
          [
            _vm.detailRoutes.loginSecurityUserGrantIpCreate,
            _vm.detailRoutes.loginSecurityUserGrantIpEdit
          ].includes(_vm.$route.name)
        )?_c('UserGrantedIpsEdit',{attrs:{"route-to-whitelist":_vm.detailRoutes.loginSecurityUserGrantIpTable}}):(_vm.$route.name === _vm.detailRoutes.settings)?_c('clientSettings',{attrs:{"route-to-table":_vm.detailRoutes.table}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }