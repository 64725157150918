<template>
  <div class="input-group">
    <div class="input-group-prepend" @click="copyText(inputData)">
      <span class="input-group-text">
        <i class="fal fa-copy cursor-pointer" />
      </span>
    </div>
    <input
      :ref="inputRef"
      v-model="inputData"
      :type="[showPassword ? 'text' : 'password']"
      class="form-control pr-0"
      :placeholder="placeholder"
      :name="inputName"
    />
    <div class="input-group-append" @click="togglePassword()">
      <span class="input-group-text">
        <i
          class="fal icon-lg cursor-pointer"
          :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
        ></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolsPasswordInput",
  props: ["data", "placeholder", "inputRef", "inputName"],
  data() {
    return {
      showPassword: false
    };
  },
  computed: {
    inputData: {
      get: function () {
        return this.data;
      },
      set: function (value) {
        this.$emit("passwordChanged", value);
      }
    }
  },
  methods: {
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>
