<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("client.databases") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: routeToTable })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="saveData">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <b-row>
          <b-col
            v-for="(entry, key) in client.tenant.tenantConnections"
            :key="key"
            md="6"
          >
            <b-row>
              <b-col md="12">
                <h5 class="font-weight-bold d-inline mr-2">{{ entry.type }}</h5>
                <b
                  class="btn btn-icon btn-light btn-sm mr-1"
                  @click="
                    entry.type === 'mysql' ? openMySql() : openMongo(entry)
                  "
                >
                  <i class="fal fa-database"></i>
                </b>
              </b-col>
              <b-col md="12">
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("client.databaseHost") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <div class="input-group">
                      <input
                        v-model="client.tenant.tenantConnections[key].host"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="12">
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("client.databasePort") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <div class="input-group">
                      <input
                        v-model="client.tenant.tenantConnections[key].port"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="12">
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("client.databaseName") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <div class="input-group">
                      <input
                        v-model="client.tenant.tenantConnections[key].database"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="12">
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("client.databaseUsername") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <div class="input-group">
                      <input
                        v-model="client.tenant.tenantConnections[key].username"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="12">
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("client.databasePassword") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <PasswordInput
                      :data="client.tenant.tenantConnections[key].password"
                      @passwordChanged="onPasswordChanged($event, key)"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </form>
  </div>
</template>

<script>
import Client from "@/components/Admins/Clients/clients";
import TenantConnections from "@/components/Admins/TenantConnections/tenantConnections";
import PasswordInput from "@/components/Tools/PasswordInput";
import ApiService from "@/core/services/api.service";
export default {
  components: {
    PasswordInput
  },
  props: {
    routeToTable: {
      type: String,
      default: "adminTenants"
    }
  },
  data() {
    return {
      client: {
        tenant: {
          tenantConnections: []
        }
      },
      showPasswords: {
        mysql: false,
        mongodb: false
      },
      isBusy: false
    };
  },
  mounted() {
    this.getClient();
  },
  methods: {
    onPasswordChanged(value, key) {
      this.client.tenant.tenantConnections[key].password = value;
    },
    getClient() {
      this.isBusy = true;
      let clientId = this.$route.params.id;
      Client.get(clientId)
        .then(response => {
          this.client = response.data.data;
          ApiService.setCache("clientDatabasesAdmin", this.client, 1000 * 60);
          this.isBusy = false;
        })
        .catch(error => {
          if (error.response && error.response.data) {
            this.$swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
          }
          this.isBusy = false;
        });
    },
    saveData() {
      this.isBusy = true;
      const connections = this.client.tenant.tenantConnections;
      connections.forEach(entry => {
        TenantConnections.update(entry.id, entry)
          .then(() => {
            this.isBusy = false;
          })
          .catch(error => {
            this.$swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
            this.isBusy = false;
          });
      });
      this.$toast.fire({
        icon: "success",
        title: this.$t("client.clientUpdated")
      });
    },
    async openMySql() {
      this.isBusy = true;
      let res = await Client.checkDBAuth(this.client);
      if (res.success) {
        window.open(res.url, "_blank");
        return;
      }
      this.$swal.fire({
        title: this.$t("client.error"),
        text: this.$t("client.couldNotOpenDatabase"),
        icon: "error"
      });
      this.isBusy = false;
    },
    openMongo(connection) {
      if (!connection) return;
      let url = process.env.VUE_APP_MONGO_DB_INTERFACE;
      let password = encodeURIComponent(connection.password);
      let username = encodeURIComponent(connection.username);
      url += `/login?user=${username}&password=${password}&host=${connection.host}&port=${connection.port}&database=${connection.database}`;
      window.open(url, "_blank");
    }
  }
};
</script>
