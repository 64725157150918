<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-body pt-4">
            <div class="d-flex justify-content-end"></div>
            <div class="row py-3">
              <div class="col-md-4 col-12 d-flex align-items-center">
                <div class="symbol symbol-60 symbol-lg-70 details-symbol">
                  <div
                    class="symbol-label symbol-label-profile"
                    :style="
                      companyLogo
                        ? 'background-image: url(' +
                          companyLogo +
                          '); background-color: #ffffff;'
                        : ''
                    "
                  ></div>
                </div>
              </div>
              <div class="col-md-8 col-12 row align-items-center">
                <div class="col-12">
                  <div class="font-weight-bolder font-size-h5 text-dark-75">
                    <b-skeleton v-if="!client.id" type="text" height="1.5rem" />
                    <span>{{ client.name }}</span>
                  </div>
                  <div class="text-muted">
                    <b-skeleton v-if="!client.id" type="text" width="60%" />
                    <span>{{ client.number }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route }"
                  custom
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{
                      active:
                        isExactActive ||
                        ($route.name.includes(item.route) &&
                          item.route !== detailRoutes.details)
                    }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 client-details">
        <clientInformation
          v-if="$route.name === detailRoutes.details"
          :route-to-table="detailRoutes.table"
          @client-updated="loadClient"
        />
        <clientContact
          v-else-if="$route.name === detailRoutes.contact"
          :route-to-table="detailRoutes.table"
        />
        <clientBilling
          v-else-if="$route.name === detailRoutes.billing"
          :route-to-table="detailRoutes.table"
        />
        <clientAccounting
          v-else-if="$route.name === detailRoutes.accounting"
          :route-to-table="detailRoutes.table"
        />
        <clientStorage
          v-else-if="$route.name === detailRoutes.storage"
          :route-to-table="detailRoutes.table"
        />
        <clientDatabases
          v-else-if="$route.name === detailRoutes.database"
          :route-to-table="detailRoutes.table"
        />
        <clientLoginSecurity
          v-else-if="$route.name === detailRoutes.loginSecurity"
          :route-to-table="detailRoutes.table"
          :route-to-whitelist="detailRoutes.loginSecurityUserGrantIpTable"
        />
        <UserGrantedIpsTable
          v-else-if="$route.name === detailRoutes.loginSecurityUserGrantIpTable"
          :route-to-login-security="detailRoutes.loginSecurity"
          :route-to-whitelist-create="
            detailRoutes.loginSecurityUserGrantIpCreate
          "
          :route-to-whitelist-edit="detailRoutes.loginSecurityUserGrantIpEdit"
        />
        <UserGrantedIpsEdit
          v-else-if="
            [
              detailRoutes.loginSecurityUserGrantIpCreate,
              detailRoutes.loginSecurityUserGrantIpEdit
            ].includes($route.name)
          "
          :route-to-whitelist="detailRoutes.loginSecurityUserGrantIpTable"
        />
        <clientSettings
          v-else-if="$route.name === detailRoutes.settings"
          :route-to-table="detailRoutes.table"
        />
      </div>
    </div>
  </div>
</template>

<style>
.disabledTab {
  display: none;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Clients from "@/components/Admins/Clients/clients";
import clientInformation from "@/components/Admins/Clients/Details/Information";
import clientContact from "@/components/Admins/Clients/Details/Contact";
import clientBilling from "@/components/Admins/Clients/Details/Billing";
import clientAccounting from "@/components/Admins/Clients/Details/Accounting";
import clientStorage from "@/components/Admins/Clients/Details/Storage";
import clientDatabases from "@/components/Admins/Clients/Details/Databases";
import clientLoginSecurity from "@/components/Admins/Clients/Details/LoginSecurity";
import clientSettings from "@/components/Admins/Clients/Details/Settings";
import UserGrantedIpsTable from "@/components/Tenants/UserGrantIps/Table";
import UserGrantedIpsEdit from "@/components/Tenants/UserGrantIps/Edit";
import { bus } from "@/main";
import Media from "@/components/Admins/Media/media";
import { mapGetters } from "vuex";

export default {
  name: "AdminsClientsDetails",
  components: {
    clientInformation,
    clientContact,
    clientBilling,
    clientAccounting,
    clientStorage,
    clientDatabases,
    clientLoginSecurity,
    clientSettings,
    UserGrantedIpsTable,
    UserGrantedIpsEdit
  },
  data() {
    return {
      detailRoutes: {},
      adminRoutes: {
        breadcrumbTable: "/admin/clients",
        table: "adminTenants",
        details: "adminTenantsDetails",
        contact: "adminTenantsDetailsContact",
        billing: "adminTenantsDetailsBilling",
        accounting: "adminTenantsDetailsAccounting",
        storage: "adminTenantsDetailsStorage",
        database: "adminTenantsDetailsDatabases",
        loginSecurity: "adminTenantsDetailsLoginSecurity",
        loginSecurityUserGrantIpTable:
          "adminTenantsDetailsLoginSecurityWhitelist",
        loginSecurityUserGrantIpCreate:
          "adminTenantsDetailsLoginSecurityWhitelistCreate",
        loginSecurityUserGrantIpEdit:
          "adminTenantsDetailsLoginSecurityWhitelistEdit",
        settings: "adminTenantsDetailsSettings"
      },
      partnerRoutes: {
        breadcrumbTable: "/partner/tenants",
        table: "partnerTenants",
        details: "partnerTenantsDetails",
        contact: "partnerTenantsDetailsContact",
        billing: "partnerTenantsDetailsBilling",
        accounting: "partnerTenantsDetailsAccounting",
        storage: "partnerTenantsDetailsStorage",
        database: "partnerTenantsDetailsDatabases",
        loginSecurity: "partnerTenantsDetailsLoginSecurity",
        loginSecurityUserGrantIpTable:
          "partnerTenantsDetailsLoginSecurityWhitelist",
        loginSecurityUserGrantIpCreate:
          "partnerTenantsDetailsLoginSecurityWhitelistCreate",
        loginSecurityUserGrantIpEdit:
          "partnerTenantsDetailsLoginSecurityWhitelistEdit",
        settings: "partnerTenantsDetailsSettings"
      },
      companyLogo: "",
      client: {},
      tabIndex: 0
    };
  },
  computed: {
    ...mapGetters(["selectedClient"]),
    tabNavigation: function () {
      return [
        {
          route: this.detailRoutes.details,
          label: "client.informationClient",
          icon: "fal fa-user"
        },
        {
          route: this.detailRoutes.contact,
          label: "client.contactData",
          icon: "fal fa-paper-plane"
        },
        {
          route: this.detailRoutes.billing,
          label: "client.billing",
          icon: "fal fa-receipt"
        },
        {
          route: this.detailRoutes.accounting,
          label: "client.accounting",
          icon: "fal fa-book-open"
        },
        {
          route: this.detailRoutes.storage,
          label: "client.storage",
          icon: "fal fa-cube"
        },
        {
          route: this.detailRoutes.database,
          label: "client.databases",
          icon: "fal fa-layer-group"
        },
        {
          route: this.detailRoutes.loginSecurity,
          label: "settings.loginSecurity",
          icon: "fal fa-shield"
        },
        {
          route: this.detailRoutes.settings,
          label: "settings.settings",
          icon: "fal fa-gear"
        }
      ];
    }
  },
  watch: {
    selectedClient: function () {
      this.loadClient();
    }
  },
  mounted() {
    if (this.$route.name.startsWith("admin")) {
      this.detailRoutes = this.adminRoutes;
    } else if (this.$route.name.startsWith("partner")) {
      this.detailRoutes = this.partnerRoutes;
    }
    if (this.$route.params.id !== toString(this.client.id)) this.loadClient();
    bus.$on("clientUpdated", this.loadClient);
  },
  destroyed() {
    bus.$off("clientUpdated", this.loadClient);
  },
  methods: {
    loadClient() {
      let self = this;
      this.client = {};
      let clientId = this.$route.params.id;
      Clients.get(clientId)
        .then(response => {
          this.client = response.data.data;

          if (this.client.logo_id !== null) {
            Media.get(this.client.logo_id)
              .then(mediaResponse => {
                self.companyLogo = mediaResponse.data.data.link;
              })
              .catch(error => {
                this.$swal.fire({
                  title: this.$t("general.caution"),
                  text: error.response.data.message,
                  icon: "error"
                });
              });
          } else {
            self.companyLogo = "";
          }

          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: this.$t("menu.adminTenants"),
              route: ""
            },
            {
              title: this.$t("general.overview"),
              route: this.detailRoutes.breadcrumbTable
            },
            {
              title: this.client.name,
              route: this.detailRoutes.breadcrumbTable + "/" + this.client.id
            }
          ]);
        })
        .catch(e => {
          console.log(e);
        });
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  }
};
</script>
