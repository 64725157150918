<template>
  <div>
    <div
      id="loginSecurityCard"
      class="card card-custom grid-stack-item-content"
    >
      <div class="card-header" style="cursor: move">
        <div class="card-title">
          <h3 class="card-label">{{ $t("settings.loginSecurity") }}</h3>
        </div>
        <div class="card-toolbar">
          <button
            class="btn btn-secondary mr-2"
            @click="$router.push({ name: routeToTable })"
          >
            {{ $t("general.cancel") }}
          </button>
          <button class="btn btn-primary" @click="save">
            {{ $t("general.save") }}
          </button>
        </div>
      </div>
      <v-progress-linear v-if="isBusy" indeterminate color="primary" />
      <form class="form">
        <div class="card-body">
          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label">
              {{ $t("settings.workspaceDomain") }}
            </label>
            <div class="col-md-9 col-lg-6 col-xl-4">
              <div class="input-group p-0">
                <b-form-tags
                  v-model="workspace"
                  separator=","
                  :add-button-text="$t('client.workspaceAdd')"
                  :placeholder="$t('client.workspaceAdd') + '...'"
                  :duplicate-tag-text="$t('client.workspaceDomainExists')"
                  remove-on-delete
                  tag-variant="primary"
                ></b-form-tags>
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label">
              {{ $t("settings.securityMaxAttempts") }}
            </label>
            <div class="col-md-9 col-lg-6 col-xl-4">
              <div class="input-group">
                <input
                  v-model="securitySettings.maxAttempts"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label">
              {{ $t("settings.securityLockMinutesAfterMaxAttempts") }}
            </label>
            <div class="col-md-9 col-lg-6 col-xl-4">
              <div class="input-group">
                <input
                  v-model="securitySettings.lockMinutesAfterMaxAttempts"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label">
              {{ $t("settings.securityMaxAttemptsBeforeLock") }}
            </label>
            <div class="col-md-9 col-lg-6 col-xl-4">
              <div class="input-group">
                <input
                  v-model="securitySettings.maxAttemptsBeforeLock"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label">
              {{ $t("settings.securityCheckIp") }}
            </label>
            <div class="col-md-4 col-lg-3 col-xl-2">
              <span class="switch switch-sm">
                <label>
                  <input v-model="securitySettings.checkIP" type="checkbox" />
                  <span></span>
                </label>
              </span>
            </div>
            <div
              v-if="securitySettings.checkIP"
              class="col-md-5 col-lg-3 col-xl-2"
            >
              <button
                class="btn btn-primary position-absolute"
                style="right: 0; top: 0"
                @click="$router.push({ name: routeToWhitelist })"
              >
                {{ $t("settings.editIps") }}
              </button>
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label">
              {{ $t("settings.securityTwoFaCheck") }}
            </label>
            <div class="col-md-9 col-lg-6 col-xl-4">
              <span class="switch switch-sm">
                <label>
                  <input
                    v-model="securitySettings.twoFaCheck"
                    type="checkbox"
                  />
                  <span></span>
                </label>
              </span>
            </div>
          </div>
          <div
            v-show="securitySettings.twoFaCheck"
            class="form-group row align-items-center"
          >
            <label class="col-xl-3 col-lg-3 col-form-label">
              {{ $t("settings.securityTwoFaCodeExpireAfterMinutes") }}
            </label>
            <div class="col-md-9 col-lg-6 col-xl-4">
              <div class="input-group">
                <input
                  v-model="securitySettings.twoFaCodeExpireAfterMinutes"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div
            v-show="securitySettings.twoFaCheck"
            class="form-group row align-items-center"
          >
            <label class="col-xl-3 col-lg-3 col-form-label">
              {{ $t("settings.twoFaDeviceExpireAfterDays") }}
            </label>
            <div class="col-md-9 col-lg-6 col-xl-4">
              <div class="input-group">
                <input
                  v-model="securitySettings.twoFaDeviceExpireAfterDays"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Settings from "@/components/Tenants/Settings/settings";
import Swal from "sweetalert2";
import Clients from "@/components/Admins/Clients/clients";
import ApiService from "@/core/services/api.service";

export default {
  name: "AdminsClientsDetailsLoginSecurity",
  props: {
    routeToTable: {
      type: String,
      default: "adminTenants"
    },
    routeToWhitelist: String
  },
  data() {
    return {
      isBusy: false,
      workspace: [],
      client: {},
      securitySettings: {
        maxAttempts: 3,
        maxAttemptsBeforeLock: 5,
        lockMinutesAfterMaxAttempts: 5,
        twoFaCheck: true,
        twoFaCodeExpireAfterMinutes: 10,
        twoFaDeviceExpireAfterDays: 30,
        checkIP: false
      },
      settings: {}
    };
  },
  mounted() {
    this.getClient(this.$route.params.id);
  },
  methods: {
    getSettings() {
      this.isBusy = true;
      Settings.getByName("loginSecurity", this.client.api_token)
        .then(response => {
          this.settings = response.data.data;
          this.securitySettings = this.settings.value;
          this.isBusy = false;
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    getClient(id) {
      Clients.get(id)
        .then(response => {
          this.workspace = response.data.data.tenant.workspace;
          this.client = response.data.data;

          ApiService.setHeader("x-api-key", this.$store.getters.apiToken);

          this.getSettings();
        })
        .catch(error => {
          console.log(error);
        });
    },
    saveWorkspace() {
      const data = {
        workspace: this.workspace,
        name: this.client.name
      };
      Clients.update(this.client.id, data)
        .then(() => {
          this.isBusy = false;
        })
        .catch(error => {
          if (error.response.status && error.response.status === 422) {
            const errorData = error.response.data;
            Object.keys(errorData.errors).forEach(fieldName => {
              const fieldData = errorData.errors[fieldName];
              this.$swal.fire({
                title: this.$t("general.caution"),
                text: this.$t(fieldData[0] + '.workspace', { domain: fieldName }),
                icon: "error"
              });
            });
            this.isBusy = false;
            return;
          }

          this.$swal.fire({
            title: this.$t("general.caution"),
            text: this.$t("client.clientWorkspaceUpdateError"),
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    save() {
      this.isBusy = true;
      this.settings.value = this.securitySettings;
      Settings.update(this.settings.id, this.settings, this.client.api_token)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("client.clientUpdated")
          });
          this.getSettings();
          this.saveWorkspace();
          this.isBusy = false;
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
