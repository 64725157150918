<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("client.informationClient") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button v-show="edit" class="btn btn-secondary">
          {{ $t("general.save") }}
        </button>
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: routeToTable })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button v-show="!edit" class="btn btn-primary" @click="saveClient">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <form class="form">
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">{{ $t("client.baseData") }}:</h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.number") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <input
                v-model="client.number"
                type="text"
                class="form-control"
                readonly
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fal fa-lock icon-lg"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.active") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <span class="switch switch-sm">
              <label>
                <input v-model="client.active" type="checkbox" />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.uuid") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-copy" />
                </span>
              </div>
              <input
                v-model="client.uuid"
                type="text"
                class="form-control pr-0"
                readonly
                @click="copyText(client.uuid)"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fal fa-lock icon-lg"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.apiToken") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-copy" />
                </span>
              </div>
              <input
                v-model="client.api_token"
                type="text"
                class="form-control pr-0"
                readonly
                @click="copyText(client.api_token)"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fal fa-lock icon-lg"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">{{ $t("client.companyData") }}:</h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.logo") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <ImageUploadCropper
              aspect-ratio=""
              :default-image="companyLogo"
              :upload-button-text="$t('companyData.uploadLogo')"
              :crop-button-text="$t('companyData.cropLogo')"
              :remove-button-text="$t('companyData.removeLogo')"
              @cropMode="edit = $event"
              @fileUpdated="files = $event"
              @fileRemoved="fileRemoved()"
            >
            </ImageUploadCropper>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.name") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input v-model="client.name" type="text" class="form-control" />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.nameShort") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="client.name_short"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.language") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <v-select
                v-model="client.language_id"
                class="form-control"
                :items="languageOptions"
                item-text="label"
                item-value="id"
                :menu-props="{ offsetY: true }"
              />
              <div class="input-group-append">
                <span class="input-group-text border-0">
                  <i class="fal fa-earth-europe icon-lg" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label mb-md-3">
            {{ $t("client.assignedPartner") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <v-select
                v-model="client.partner_id"
                class="form-control"
                :items="assignedPartner"
                item-text="name"
                item-value="id"
                :menu-props="{ offsetY: true }"
                :no-data-text="$t('client.noPartner')"
                @change="partnerChanged(client.partner_id)"
              />
              <div class="input-group-append">
                <span class="input-group-text border-0">
                  <i class="fal fa-user-tie icon-lg" />
                </span>
              </div>
            </div>
            <span class="form-text text-muted">{{
              $t("partner.assignedPartnerLabel")
            }}</span>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label mb-md-3">
            {{ $t("client.contactPerson") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <v-select
                v-model="client.main_contact_id"
                class="form-control"
                :items="contactPersons"
                item-text="name"
                item-value="id"
                :menu-props="{ offsetY: true }"
                :no-data-text="$t('client.noContactPersons')"
              />
              <div class="input-group-append">
                <span class="input-group-text border-0">
                  <i class="fal fa-user-tie icon-lg" />
                </span>
              </div>
            </div>
            <span class="form-text text-muted">{{
              $t("partner.contactPersonPartner")
            }}</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Clients from "@/components/Admins/Clients/clients";
import Partners from "@/components/Admins/Partners/partners";
import Users from "@/components/Tenants/Users/users";

import Languages from "@/components/General/Languages/languages";
import Media from "@/components/Admins/Media/media";
import ImageUploadCropper from "@/components/Admins/Media/ImageUploadCropper";
import Swal from "sweetalert2";
import { bus } from "@/main";

export default {
  name: "AdminsClientsDetailsInformation",
  components: {
    ImageUploadCropper
  },
  props: {
    routeToTable: {
      type: String,
      default: "adminTenants"
    }
  },
  data() {
    return {
      files: [],
      edit: false,
      companyLogo: "",
      client: {},
      companyData: {},
      languageOptions: [],
      contactPersons: [],
      assignedPartner: [],
      isBusy: false,
      oldLogoId: null,
      partner: {}
    };
  },
  mounted() {
    this.loadClient();
    this.getLanguages();
    this.getPartners();
  },
  methods: {
    loadClient() {
      let self = this;
      this.isBusy = true;
      let clientId = this.$route.params.id;
      Clients.get(clientId)
        .then(response => {
          this.client = response.data.data;
          if (this.client.language !== null) {
            this.client.language_id = this.client.language.id;
          }
          if (this.client.logo_id !== null) {
            self.oldLogoId = this.client.logo_id;
            Media.get(this.client.logo_id)
              .then(async mediaResponse => {
                self.companyLogo = mediaResponse.data.data.link;
              })
              .catch(error => {
                this.$swal.fire({
                  title: this.$t("general.caution"),
                  text: error.response.data.message,
                  icon: "error"
                });
              });
          }
          this.getContactPersons(this.client.partner_id);
          this.isBusy = false;
        })
        .catch(e => {
          console.log(e);
          this.isBusy = false;
        });
    },
    async saveClient() {
      this.isBusy = true;
      let self = this;
      let oldLogoId = self.oldLogoId;
      this.client.main_contact = this.contactPersons[
        this.client.main_contact_id - 1
      ];

      if (this.files.length) {
        let mediaData = new FormData();
        mediaData.append("files[]", this.files[0].file);
        mediaData.append("description[]", "Logo - " + self.client.name);
        await Media.store(mediaData)
          .then(response => {
            if (response.data.data.success) {
              self.client.logo_id = response.data.data.uploaded[0].id;
            }
          })
          .catch(error => {
            Swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
            this.isBusy = false;
          });
      }

      Clients.update(self.client.id, self.client)
        .then(() => {
          if (this.files.length || !this.client.logo_id) {
            self.deleteLogo(oldLogoId);
          }

          this.loadClient();
          this.$toast.fire({
            icon: "success",
            title: this.$t("client.clientUpdated")
          });
          bus.$emit("clientUpdated");
          bus.$emit("reloadHeaderFilter");
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    deleteLogo(logoId = this.client.logo_id) {
      if (logoId !== null) {
        Media.delete(logoId);
      }
    },
    fileRemoved() {
      this.files = [];
      this.companyLogo = false;
      this.client.logo_id = null;
    },
    getLanguages() {
      Languages.getAll()
        .then(response => {
          this.languageOptions = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    partnerChanged(id) {
      this.contactPersons = [];
      this.getContactPersons(id);
    },
    getPartners() {
      const params = {
        size: 0,
        noPagination: true
      };
      Partners.getAll(params)
        .then(response => {
          this.assignedPartner = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    getContactPersons(id) {
      const params = { size: 0 };

      let partnerId = id;

      if (!partnerId) {
        return [];
      }

      Partners.get(partnerId)
        .then(response => {
          this.partner = response.data.data;
          const apiKey = "Bearer " + this.partner.api_token;

          const headers = {
            headers: {
              "x-api-key": apiKey,
              "x-partner-key": apiKey
            }
          };

          Users.getAll(params, headers)
            .then(response => {
              if (response.data.data !== "") {
                this.contactPersons = response.data.data;
              } else {
                this.contactPersons = [];
                this.client.partner_id = null;
              }
            })
            .catch(error => {
              this.$swal.fire({
                title: this.$t("general.caution"),
                text: error.response.data.message,
                icon: "error"
              });
            });
        })
        .catch(e => {
          console.log(e);
        });
    },
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#profile-image {
  background-image: url(~@/assets/media/users/blank.png);

  .image-input-wrapper {
    background-size: contain;
    background-position: center;
  }

  .icon-undo {
    font-size: 0.8rem;
  }
}

.v-text-field {
  margin-top: 0;
}
</style>
