<template>
  <div class="card card-custom grid-stack-item-content">
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 v-if="edit" class="card-label font-weight-bolder text-dark">
          {{ $t("usersGrantIps.updateEntry") }}
        </h3>
        <h3 v-else class="card-label font-weight-bolder text-dark">
          {{ $t("usersGrantIps.addNewEntry") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-secondary mr-2" @click="cancel">
          {{ $t("general.cancel") }}
        </button>
        <button
          :class="saveButtonEnabled ? 'btn-primary' : 'btn-secondary'"
          class="btn"
          :disabled="isBusy"
          @click="save"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <div class="form-group row align-items-center">
        <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
          {{ $t("usersGrantIps.user") }}
        </label>
        <div class="col-md-9 col-lg-10 col-xl-9">
          <div class="input-group">
            <v-select
              v-model="data.user_id"
              class="form-control"
              :class="!$v.data.user_id.required ? 'is-invalid' : 'is-valid'"
              :items="users"
              item-text="name"
              item-value="id"
              :menu-props="{ offsetY: true }"
              @mouseleave="validateFields"
            />
            <b-form-invalid-feedback
              v-if="!$v.data.user_id.required"
              id="userid-feedback"
            >
              {{ $t("validation.required.user") }}
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
          {{ $t("usersGrantIps.ipAddress") }}
        </label>
        <div class="col-md-9 col-lg-10 col-xl-9">
          <b-form-input
            id="ip"
            v-model="data.ip"
            type="text"
            aria-describedby="ip-help ip-feedback"
            class="form-control"
            :class="!$v.data.ip.required ? 'is-invalid' : 'is-valid'"
            @input="validateFields"
          ></b-form-input>
          <b-form-invalid-feedback v-if="!$v.data.ip.required" id="ip-feedback">
            {{ $t("validation.required.ipAddress") }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
          {{ $t("usersGrantIps.description") }}
        </label>
        <div class="col-md-9 col-lg-10 col-xl-9">
          <input v-model="data.description" type="text" class="form-control" />
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
          {{ $t("general.active") }}
        </label>
        <div class="col-md-9 col-lg-10 col-xl-9">
          <span class="switch switch-sm">
            <label>
              <input v-model="data.active" type="checkbox" />
              <span></span>
            </label>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Users from "@/components/Tenants/Users/users";
import UserGrantIps from "@/components/Tenants/UserGrantIps/userGrantIps";
import { required } from "vuelidate/lib/validators";

export default {
  validations: {
    data: {
      user_id: { required },
      ip: { required }
    }
  },
  props: {
    routeToWhitelist: String
  },
  data() {
    return {
      saveButtonEnabled: false,
      users: [],
      edit: false,
      isBusy: false,
      data: {
        user_id: "",
        ip: "",
        description: "",
        active: true
      }
    };
  },
  mounted() {
    this.getUsers();

    const id = this.$route.params.whitelistId;
    this.edit = id !== undefined;
    if (this.edit) {
      this.getUserGrantIpData(id);
    }
  },
  methods: {
    getUsers() {
      const params = {
        page: 1,
        size: 100
      };
      Users.getAll(params)
        .then(response => {
          let users = response.data.data;
          users.unshift({
            name: this.$t("users.allUsers"),
            id: "all"
          });
          this.users = users;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getUserGrantIpData(id) {
      this.isBusy = true;
      UserGrantIps.get(id)
        .then(response => {
          this.data = response.data.data;
          if (this.data.user_id === null) {
            this.data.user_id = "all";
          }
          this.validateFields();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    validateFields() {
      this.$v.$touch();
      this.saveButtonEnabled = !this.$v.$invalid;
    },
    save() {
      this.isBusy = true;
      let saveData = this.data;
      if (this.data.user_id === "all") {
        saveData.user_id = null;
      }

      if (this.edit) {
        this.updateUserGrantIp(saveData);
        return;
      }
      this.storeUserGrantIp(saveData);
    },
    storeUserGrantIp(data) {
      UserGrantIps.store(data)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("usersGrantIps.userGrantedIpCreated")
          });
          this.cancel();
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    updateUserGrantIp(data) {
      UserGrantIps.update(this.data.id, data)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("usersGrantIps.userGrantedIpUpdated")
          });
          this.cancel();
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    cancel() {
      this.$router.push({ name: this.routeToWhitelist });
    }
  }
};
</script>

<style scoped></style>
